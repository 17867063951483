import React, { useState, useEffect, Suspense } from 'react';
import logo from './logo.svg';
import titleImg from './title-img.jpg';
import './App.css';
import { OnResultFunction, QrReader } from 'react-qr-reader';

{/* interface TestMode {
name: string | null | undefined;
}

declare type CallbackTestMode = (
  val1: TestMode | null | undefined
) => void;

const callback: CallbackTestMode = (val1) => {
  let aaa = val1?.name ?? "JINWON"
} */}

function App() {
  const [permissionGranted, setPermissionGranted] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [facingMode, setFacingMode] = useState<'user' | 'environment'>('environment');
  const [data, setData] = useState<string | null>(null);
  const [currentPosition, setCurrentPosition] = useState<null | GeolocationCoordinates>(null);

  // useEffect(() => {
  //   async function requestCameraPermission() {
  //     try {
  //       const stream = await navigator.mediaDevices.getUserMedia({ video: true });
  //       setPermissionGranted(true);
  //       // 카메라 사용 후 스트림을 멈춥니다.
  //       stream.getTracks().forEach(track => track.stop());
  //     } catch (error) {
  //       setErrorMessage('Camera permission denied');
  //     }
  //   }

  //   requestCameraPermission();

  //   const watchId = navigator.geolocation.watchPosition(
  //     position => {
  //       setCurrentPosition(position.coords);
  //     },
  //     error => {
  //       console.error(error);
  //       setErrorMessage('Geolocation error');
  //     },
  //     {
  //       enableHighAccuracy: false,
  //       timeout: 15000,
  //       maximumAge: 0
  //     }
  //   );

  //   // 컴포넌트 언마운트 시 watchPosition 해제
  //   return () => {
  //     navigator.geolocation.clearWatch(watchId);
  //   };

  // }, []);

  // const handleScan: OnResultFunction = (result, error) => {
  //   let resultValue = result?.getText() ?? ""
  //   setData(resultValue);
  // };

  // const toggleFacingMode = () => {
  //   setFacingMode((prevMode) => (prevMode === 'user' ? 'environment' : 'user'));
  // };

  // const handleError = (err: any) => {
  //   console.error(err);
  //   setErrorMessage('QR Code scanning error');
  // };

  // const constraints: MediaTrackConstraints = {
  //   facingMode: facingMode
  // }

  return (
    <div className="App">
      <div>
        <h1>APP TEST</h1>
        <select id="cars" name="cars">
          <option value="volvo">Volvo</option>
          <option value="saab">Saab</option>
          <option value="mercedes">Mercedes</option>
          <option value="audi">Audi</option>
        </select>
        {currentPosition ? (
          <div>
            <p>Latitude: {currentPosition.latitude}</p>
            <p>Longitude: {currentPosition.longitude}</p>
          </div>
        ) : (
          <p>Getting position...</p>
        )}
        {errorMessage && <p>{errorMessage}</p>}
        {permissionGranted ? (
          // <>
          //   <QrReader
          //     onResult={handleScan}
          //     constraints={constraints}
          //   />
          //   <button onClick={toggleFacingMode}>
          //     Switch to {facingMode === 'user' ? 'Rear' : 'Front'} Camera {facingMode}
          //   </button>
          // </>
          <div>
            PWA 앱입니다.
          </div>
        ) : (
          <p>Requesting camera permission...</p>
        )}
        {/* <Suspense fallback={<div>Loading...</div>}>
        </Suspense> */}
        {data && <p>Scanned Data: {data}</p>}
      </div>
    </div>
  );
}

export default App;
